import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { base_url } from "../../server";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import { auth } from "../../FireBase";
import { firebase, auth } from '../../FireBase';
import { useTranslation } from "react-i18next";



function OtpLogin(props) {


    const [show, setShow] = useState(true)
    // const [logIn, setLogIn] = useState({
    //     entity: ''
    // })
    // const [otp, setSetOtp] = useState({
    //     token: '',
    //     otp: ''
    // })
    // const navigate = useNavigate()


    const navigate = useNavigate()
    const handleShow = () => {
        if (show) {
            setShow(!show)
        } else {
            setShow(!show)
        }


    }


    // const handleLogin = (e) => {
    //     const clone = { ...logIn }
    //     const val = e.target.value
    //     const name = e.target.name
    //     clone[name] = val
    //     setLogIn(clone)
    // }

    // const handleOtp = (e) => {
    //     const clone = { ...otp }
    //     const val = e.target.value
    //     const name = e.target.name
    //     clone[name] = val
    //     setSetOtp(clone)
    // }

    // const handleClickOtp = async () => {
    //     try {
    //         const res = await axios.post(`${base_url}user/verifyContact`, logIn)
    //         console.log(res.data.token);
    //         handleShow()
    //         window.localStorage.setItem("otptoken", res?.data.token);
    //     } catch (error) {

    //     }
    // }

    // const otpVerify = async () => {
    //     try {
    //         const res = await axios.post(`${base_url}user/verifyOtp`, { token: window.localStorage.getItem("otptoken"), otp: otp.otp })
    //         props.onHide()
    //         window.localStorage.setItem("isLogin", true);
    //         window.localStorage.setItem("token", res?.data.token);
    //         navigate("/");
    //     } catch (error) {
    //         alert(error)
    //     }
    // }

    const [phone, setPhone] = useState('')
    const [otp, setOtp] = useState('');
    const [final, setfinal] = useState('');

    console.log('final', final);

    const [resCaptcha, setReCaptcha] = useState()
    // const [confirmationResult, setConfirmationResult] = useState(null)
    // console.log(confirmationResult);

    // const sendOtp = async () => {
    //     alert('ijnji')
    //     try {
    //         if (resCaptcha === undefined) {
    //             resCaptcha = new RecaptchaVerifier(
    //                 'reCaptcha-container',
    //                 {
    //                     size: 'invisible',
    //                 },
    //                 auth
    //             );
    //         }
    //         let recaptcha = new RecaptchaVerifier(auth, 'recaptcha', {});
    //         console.log('recaptcha', recaptcha);
    //         signInWithPhoneNumber(auth, phone, resCaptcha).then((result) => {
    //             console.log('result', result);

    //         }).catch((errr) => {
    //             console.log('errr', errr);
    //         })
    //         console.log('sefefw');

    //     } catch (error) {
    //         console.log('error', error);
    //     }

    // }
    const [token, setToken] = useState()

    const [validToken, setValidToken] = useState({
        id: token
    })



    const sendOtp = () => {

        if (phone === "" || phone.length < 10) return;

        // try {
        //     let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        //     auth.signInWithPhoneNumber(phone, verify).then((result) => {
        //         setfinal(result);
        //         console.log('result', result);
        //         alert("code sent")
        //         handleShow()
        //     })
        // } catch (error) {
        //     alert(error);
        //     window.location.reload()
        // }

        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        auth.signInWithPhoneNumber(phone, verify).then((result) => {
            setfinal(result);
            // console.log('result', result.verificationId);
            alert("code sent")
            handleShow()
        })
            .catch((err) => {
                alert(err);
                // window.location.reload()
            });
    }

    const ValidateOtp = async () => {
        if (otp === null || final === null)
            return;
        const res = await final.confirm(otp).then((result) => {
            console.log('result----', otp, result.user._lat);
            setToken(result.user.za)
            postData(result.user._lat)
            // navigate('/')

        }).catch((err) => {
            alert("Wrong code");
        })


        // setValidToken()
    }


    const postData = async (toke) => {
        const obj = { id: toke }
        try {
            const res = await axios.post(`${base_url}auth/phone/verify`, obj)
            console.log('login', res);
            window.localStorage.setItem("token", res.data.token);
            window.localStorage.setItem('isLogin', true)
            // window.localStorage.setItem("user_id", res.data._id);
            navigate('/')
        } catch (error) {

        }


    }

    // useEffect(() => {
    //     postData()
    // }, [])

    const { t } = useTranslation()
    return (
        <>
            {/* <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login / Signup
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {show ? <>
                        <div className="otp-login">
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Mobile Number</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="entity" value={logIn.entity} onChange={handleLogin} />
                            
                            </div>
                            <button type="button" onClick={handleClickOtp} className="btn btn-primary">GET OTP</button>
                        </div>
                    </> : <>
                        <div className="otp-login verify-otp">
                            <h2>Verify OTP</h2>
                            <p>Verify Mobile Number 6206958466 <Link to="#" onClick={() => handleShow()}>Change</Link></p>
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Enter OTP</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="otp" value={otp.otp} onChange={handleOtp} />
    
                            </div>
                            <button type="button" onClick={otpVerify} className="btn btn-primary">Verify</button>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal> */}
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('Login / Signup')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {show && <div id="recaptcha-container"></div>} */}

                    {show ? <>
                        <div className="otp-login">
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">{t('Mobile Number')}</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="phone" value={phone} onChange={(e) => { setPhone(e.target.value) }} placeholder="+91" />
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                                <div id="recaptcha-container"></div>
                            </div>
                            <button type="button" onClick={sendOtp} className="btn btn-primary">{t('GET OTP')}</button>
                        </div>
                    </> : <>
                        <div className="otp-login verify-otp">
                            <h2>{t('Verify OTP')}</h2>
                            <p>{t('Verify Mobile Number')}{phone}<Link to="#" onClick={() => handleShow()}>{t('Change')}</Link></p>
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">{t('Enter OTP')}</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="otp" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <button type="button" onClick={ValidateOtp} className="btn btn-primary">{t('Verify')}</button>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>{t('Close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default OtpLogin