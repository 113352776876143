import React, { useEffect, useState } from "react";
import fertilizer1 from "../../../../assets/img/products/1.jpg";
import Rating from "../../../../shared/rating/Rating";

import { FiSearch } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import { BsFillCartFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";

// You need to import the CSS only once
// import "react-awesome-lightbox/build/style.css";

import "./ProductItem.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ImgesData } from "../../proImgs/ImgesData";
import axios from "axios";
import { setCartLists, useOfflineAddPostMutation, useSetCartMutation } from "../../../products/productSlice";
import { useDispatch, useSelector } from "react-redux";

import { featuredDB } from "../../../../rki-data/category";


import tp1 from '../../../../assets/img/leatzmeat/product/tp-1.jpg'
import tp2 from '../../../../assets/img/leatzmeat/product/tp-2.jpg'
import tp3 from '../../../../assets/img/leatzmeat/product/tp-3.jpg'
import tp4 from '../../../../assets/img/leatzmeat/product/tp-4.jpg'
import tp5 from '../../../../assets/img/leatzmeat/product/tp-5.jpg'
import { useTranslation } from "react-i18next";
import { base_url } from "../../../../server";

const productData = [
  { _id: "2", proUrl: tp2, title: "Chicken Curry Cut - Small Pieces" },
  { _id: "3", proUrl: tp3, title: "Afghani Murgh Seekh Kebab" },
  { _id: "4", proUrl: tp4, title: "Chicken Breast Boneless (Large Pack)" },
  { _id: "1", proUrl: tp1, title: "Chunky Shawarma Chicken Spread" },
  { _id: "5", proUrl: tp5, title: "Afghani Murgh Seekh Kebab" },
  { _id: "6", proUrl: tp1, title: "Chicken Breast Boneless" },
  // { _id: "7", proUrl: tp2, title: "Chicken Boneless - Mini Bites" },
  // { _id: "8", proUrl: tp5, title: "Chicken Boneless - Mini Bites" },
]

function ProductItem({ loadMore, setTotalProductLength, latestData, setLatestData, handleShow, setValue, value, data, setData }) {
  const userid = window.localStorage.getItem("user_id");

  const [error, setError] = useState(false)
  const [isLoading, SetIsloading] = useState(true)

  const getData = async (id) => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/product/page/${id}&${24}`, { withCredentials: true })
      // console.log('res---', res.data.title);
      setData(res.data)
      SetIsloading(false)
    } catch (error) {
      setError(true)
      SetIsloading(false)
    }
  }
  const param = useParams()
  // console.log('param', param);
  const getProductData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/product/search/${param.val}`, { withCredentials: true })
      // setData(res.getSearchedProduct)
      setData(res.data.getSearchedProduct);
      setValue(res.data.getSearchedProduct)
      setTotalProductLength(res.data?.getSearchedProduct)
    } catch (error) {

    }
  }

  const [page, setPage] = useState(null)
  // console.log('page', page);
  // const [page, setPage] = useState(null)
  const pagination = async () => {
    const res = await axios.get(`${base_url}product/count`, { withCredentials: true })
    let showPage = Math.floor(res?.data.count / 6)
    const reminder = Math.floor(res?.data.count % 6)

    if (reminder > 0) {
      showPage += 1
    }
    // console.log(showPage);
    // console.log('reminder', reminder);
    const arru = []
    for (let i = 0; i < showPage; i++) {
      arru.push({ page: i })

    }
    setPage(arru)
  }


  const curr = window.localStorage.getItem('currencySym')
  let currencySymbol = curr
  if (currencySymbol === 'undefined') {
    currencySymbol = 'ZK'
  }
  useEffect(() => {
    if (!data) {
      if (param.val) {
        return
      }
      getData(0)
    }
    // getProductData()
    // pagination()

  }, []);

  const [addToCart, { data: datacart, isLoading: isAddCartLoading, isSuccess: isAddToCartSuccess, isError: isAddToCartError }] = useSetCartMutation();
  const isLogin = window.localStorage.getItem("isLogin");
  const [postOffline, { data: resData, isSuccess, isError: offErr, isLoading: isloadPost }] = useOfflineAddPostMutation()
  const navigate = useNavigate()


  const { updatedProducts: products } = useSelector((state) => {
    return state.productList
  })
  const BuyNowPro = (item) => {
    if (isLogin === 'false') {
      postOffline({
        product_count: 1,
        product_variant: item?.variations[0]._id,
        deliveryType: 'HOME DELIVERY',
        seller_id: '64269f0df127906d53878d3d',
        sku: item?.variations[0].sku,
        product_id: item?._id,
        products: products ? products : []
      })

    } else {
      const payload = {
        product_count: 1,
        product_variant: item?.variations[0]._id,
        deliveryType: 'HOME DELIVERY',
        seller_id: '64269f0df127906d53878d3d',
        sku: item?.variations[0].sku,
        product_id: item?._id,
        userid,

      }
      addToCart(payload)

    }

  }

  useEffect(() => {
    if (isSuccess) {
      navigate('/cart')
    }
  }, [isSuccess])

  const dispacher = useDispatch()
  useEffect(() => {
    if (isSuccess) {
      dispacher(setCartLists(resData.cart.products))
    }
  }, [isSuccess, offErr])

  const [count, setCount] = useState(6)
  const changeLoad = () => {
    const aa = count + 6
    if (aa > data.length) {
      return
    }
    setCount(count + 6)
  }


  const { t } = useTranslation()

  return (
    <>
      {/* {isLoading ? (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      ) : null} */}
      {/* <CollectionFilter latestData={latestData} /> */}
      {data?.length === 0 && (<h6 className="text-center mb-5">No Found Data</h6>)}
      {data?.slice(0, count).map((item, i) => {
        return (
          <div className="col-lg-3 col-md-6 col-sm-12" key={item._id}>
            <div className="featuredInfo sevi">
              <div className="featuredFigure">
                {isloadPost && <div className="preloaderCount">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>}
                <div className="featuredImg" style={{ display: "flex", justifyContent: "center" }}>
                  {/* {item.mainimage_url ? <Link to={`/product/${item._id}`}>
                    <img src={item.mainimage_url} alt="Product" />
                  </Link> : <Link to={`/product/${item._id}`}>
                    <img src={fertilizer4} alt="Product" />
                  </Link>} */}
                  <Link to={`/product/${item?.uid}/${item?.slug}`}>
                    {item?.variations[0]?.mainImage_url.url ? <img src={item?.variations[0]?.mainImage_url.url} alt="Product" className="imgProduct" /> : <img src={item?.proUrl} alt="Product" />}
                  </Link>
                  <div className="quickView">
                    <ul>
                      {/* <li className="viewProduct">
                        <button
                          className="quick_view_btn"
                          onClick={(e) => {
                            handleShow(item?.uid)
                          }}
                        >
                          <FiSearch />
                        </button>
                      </li> */}
                      <li className="addProduct">
                        <Link to={`/product/${item?.uid}/${item?.slug}`}>
                          <GrAdd />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="hotList">
                  <li>
                    <Link to={`/product/${item?.uid}/${item?.slug}`}>hot</Link>
                  </li>
                  <li>
                    {/* <Link to="products">- {item?.variations[0].discount}</Link> */}
                  </li>
                </ul>
              </div>
              <div className="featuredContent">
                <h6>category</h6>
                <h5>
                  <Link to={`/product/${item?.uid}/${item?.slug}`}>{item?.name}</Link>
                </h5>
                <Rating />

                <div className="price-setting">
                  <span className="text_2">{item?.variations[0]?.attributeList[0]?.list[0]?.value} | {item?.variations[0]?.attributeList[0]?.list[1]?.value} | {item?.variations[0]?.attributeList[0]?.list[2]?.value}</span>
                  {/* <div className="SmallProductCard_price_wrapper__fR0cD"><span className="title_4 price-color">{item?.variations[0]?.mrp ? item?.variations[0]?.mrp : ''}</span>&nbsp;</div>
                  <div className="SmallProductCard_delivery_wrapper__5doc8"><span className="text_2">Tomorrow <b>8 AM - 11 AM</b></span></div> */}
                </div>
                <div className="rateDigit">
                  <span className="cross">{item?.variations[0]?.prices?.country_id?.currency_id?.symbol}{item?.variations[0]?.prices?.mrp}</span>
                  <span className="currentPrice">{item?.variations[0]?.prices?.country_id?.currency_id?.symbol}{item?.variations[0]?.prices?.sale_rate}</span>
                </div>
                {/* <div className="rateDigit">
                  {item.variations && <span className="currentPrice">Variant: </span>}
                  {
                    item.variations && item?.variations.map((item) => {
                      return <span className="currentPrice"> {item.weight},</span>
                    })
                  }
                </div> */}
                <div className="buyNowInfo">

                  <Link to={`/product/${item?.uid}/${item?.slug}`} className="btn btn-danger addCart">{t('View Detail')}</Link>
                  <Link to="#" onClick={() => { BuyNowPro(item) }} className="btn btn-primary buyNow">
                    <BsFillCartFill /> {t('Buy Now')}
                  </Link>
                </div>
                <div className="productDesc">
                  <p>{item.meta_description}</p>
                </div>
                <div className="featuredOption">
                  <select defaultValue={"DEFAULT"}>
                    <option value="DEFAULT">Select Option</option>
                    <option value="one">One</option>
                    <option value="two">Two</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
        );
      })}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button type="button" onClick={changeLoad} className="btn btn-info" disabled={count > data?.length}>{t('Load More')}</button>
      </div>

      {error && (
        <div className="alertMsg mb-4" role="alert">
          <h4 style={{ color: "red" }}>Server Error</h4>
        </div>
      )}

      {/* <nav aria-label="Page navigation example text-cneter">
        <ul className="pagination" style={{ justifyContent: "center" }}>
          {page && page?.map((item) => {
            return <li className="page-item" onClick={() => getData(item.page)}><button className="page-link">{item?.page}</button></li>
          })}
        </ul>
      </nav> */}
    </>
  );
}

export default ProductItem;
