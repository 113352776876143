import Slider from "react-slick";

import p1 from '../../../assets/img/admclick/p-1.webp'
import p2 from '../../../assets/img/admclick/p-2.webp'
import p3 from '../../../assets/img/admclick/p-3.webp'
import p4 from '../../../assets/img/admclick/p-4.webp'
import p5 from '../../../assets/img/admclick/p-5.webp'
function Promotion() {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <section className="promotion">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="fishermanHeader d-flex justify-content-between align-items-end mb-4"><div className="fisherman-content fisherman-content-2"><span>Promotions</span><h3>Promotions Deals</h3></div><div className="fisherman-btn"><a className="optional-btn optional-btn-2" href="/products">View More</a></div></div>
                        </div>
                        <Slider {...settings}>
                            <div className="param">
                                <div className="promotion-item">
                                    <div className="promotion-item-image">
                                        <a href="#">
                                            <img src={p1} alt="" />
                                        </a>
                                    </div>
                                    <div className="d-flex">
                                        <div className="ab__dotd_promotions-item_days_left">
                                            <span>46</span>
                                            <div>days left</div>
                                        </div>
                                        <div class="ab__dotd_promotions-item_title">
                                            <a href="#" title="">It’s Black Friday all month long</a>
                                            <div class="ab__dotd_promotions-item_date">
                                                to 10/27/2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="param">
                                <div className="promotion-item">
                                    <div className="promotion-item-image">
                                        <a href="#">
                                            <img src={p2} alt="" />
                                        </a>
                                    </div>
                                    <div className="d-flex">
                                        <div className="ab__dotd_promotions-item_days_left">
                                            <span>46</span>
                                            <div>days left</div>
                                        </div>
                                        <div class="ab__dotd_promotions-item_title">
                                            <a href="#" title="">It’s Black Friday all month long</a>
                                            <div class="ab__dotd_promotions-item_date">
                                                to 10/27/2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="param">
                                <div className="promotion-item">
                                    <div className="promotion-item-image">
                                        <a href="#">
                                            <img src={p3} alt="" />
                                        </a>
                                    </div>
                                    <div className="d-flex">
                                        <div className="ab__dotd_promotions-item_days_left">
                                            <span>46</span>
                                            <div>days left</div>
                                        </div>
                                        <div class="ab__dotd_promotions-item_title">
                                            <a href="#" title="">It’s Black Friday all month long</a>
                                            <div class="ab__dotd_promotions-item_date">
                                                to 10/27/2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="param">
                                <div className="promotion-item">
                                    <div className="promotion-item-image">
                                        <a href="#">
                                            <img src={p4} alt="" />
                                        </a>
                                    </div>
                                    <div className="d-flex">
                                        <div className="ab__dotd_promotions-item_days_left">
                                            <span>46</span>
                                            <div>days left</div>
                                        </div>
                                        <div class="ab__dotd_promotions-item_title">
                                            <a href="#" title="">It’s Black Friday all month long</a>
                                            <div class="ab__dotd_promotions-item_date">
                                                to 10/27/2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="param">
                                <div className="promotion-item">
                                    <div className="promotion-item-image">
                                        <a href="#">
                                            <img src={p5} alt="" />
                                        </a>
                                    </div>
                                    <div className="d-flex">
                                        <div className="ab__dotd_promotions-item_days_left">
                                            <span>46</span>
                                            <div>days left</div>
                                        </div>
                                        <div class="ab__dotd_promotions-item_title">
                                            <a href="#" title="">It’s Black Friday all month long</a>
                                            <div class="ab__dotd_promotions-item_date">
                                                to 10/27/2023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <h3>6</h3>
                            </div>
                            <div>
                                <h3>7</h3>
                            </div>
                            <div>
                                <h3>8</h3>
                            </div>
                            <div>
                                <h3>9</h3>
                            </div> */}
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Promotion