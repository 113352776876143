import img1 from "../../../assets/img/leatzmeat/product/tp-1.jpg"
import img2 from "../../../assets/img/leatzmeat/product/tp-1.jpg"
import img3 from "../../../assets/img/leatzmeat/product/tp-1.jpg"
import img4 from "../../../assets/img/leatzmeat/product/tp-1.jpg"
import img5 from "../../../assets/img/leatzmeat/product/tp-1.jpg"
import img6 from "../../../assets/img/leatzmeat/product/tp-1.jpg"
import img7 from "../../../assets/img/leatzmeat/product/tp-1.jpg"
import img8 from "../../../assets/img/leatzmeat/product/tp-1.jpg"
import img9 from "../../../assets/img/leatzmeat/product/tp-1.jpg"

export const ImgesData = [
    { url: img1 },
    { url: img8 },
    { url: img7 },
    { url: img6 },
    { url: img5 },
    { url: img2 },
    { url: img4 },
    { url: img9 },
    { url: img3 },
]