import React from "react";
import FeaturedProduct from "./featured-product/FeaturedProduct";
import { useTranslation } from "react-i18next";

function FeaturedProducts() {
  const { t } = useTranslation()
  return (
    <>
      <section className="sellerHomeInfo p-30">
        <div className="row">
          <div className="col-lg-12">
            <div className="sellerTopHeader">
              <h2>{t('Seller Products')}</h2>
              <p>
                {t('Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, impedit quos ab reprehenderit corporis odit voluptatibus atque dignissimos facere voluptate in nobis maiores, dicta saepe ullam, cumque ipsam quod quae.')}

              </p>
            </div>
          </div>
          <FeaturedProduct />
        </div>
      </section>
    </>
  );
}

export default FeaturedProducts;
