import React, { useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { clearUpdatedProduct, setCartLeng, clereCartLitsItem, useClearAllListMutation, useDeleteDataMutation, useGetCartQuery, useGetCouponMutation, useOfflineCartListMutation, useSetQuantityCartMutation, setCartCalc } from "../products/productSlice";
import { ApiQuatity } from "./ApiQuantity";
import { AiFillDelete } from "react-icons/ai";
import productSmall1 from "../../assets/img/products/productSmall1.jpg";
import LoginCartSec from "./LoginCartSec";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import CoupensList from "./CoupensList";

function Cart() {
  const isLogin = window.localStorage.getItem("isLogin")
  const user_id = window.localStorage.getItem("user_id")
  const [modalShow, setModalShow] = useState(false);
  const dispach = useDispatch()
  const curr = window.localStorage.getItem('currencySym')
  let currencySymbol = curr
  if (currencySymbol === 'undefined') {
    currencySymbol = 'ZK'
  }
  const [getDisCoupons, { isLoading, isSuccess: coupenSuss ,isError:isCoupErr}] = useGetCouponMutation()
  const token = window.localStorage.getItem('token')

  // const { data, isSuccess } = useGetCartQuery(user_id);
  const [isSuccess, setisSuccess] = useState(false)
  const [showData, setShowData] = useState(null)
  // console.log('showData', showData);
  const { updatedProducts: products, cartCal } = useSelector((state) => {
    return state.productList
  })
  const getCartData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/cart`, {
        withCredentials: true,
        headers: {
          "content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      }

      )
      // console.log('res---', res.data.cart.products[0].product.variations[0].mainImage_url.url);
      setisSuccess(true)
      setShowData(res.data)
      dispach(setCartLeng(res.data?.cart?.products.length))
      dispach(setCartCalc(res.data))
    } catch (error) {
      setisSuccess(false)
    }
  }

  useEffect(() => {
    if (isLogin) {
      getCartData()
    }
  }, [isSuccess])

  useEffect(() => {
    if (coupenSuss) {
      getCartData()
    }
    if (isCoupErr) {
      alert('Coupon Not Applied')
    }
  }, [coupenSuss,isCoupErr])


  const navigate = useNavigate()

  const [deleteList, { isSuccess: alldlete }] = useClearAllListMutation()
  const [deleteSingleItem, { isLoading: isLoadingDelete, isSuccess: isdeletesuss }] = useDeleteDataMutation()

  useEffect(() => {
    if (isdeletesuss) {
      getCartData()
    }
    if (alldlete) {
      getCartData()
      dispacher(setCartLeng(0))
    }

  }, [isdeletesuss, alldlete])



  const [getOffCartList, { data: offCartList, isSuccess: issucOff }] = useOfflineCartListMutation()

  // useEffect(() => {
  //   getOffCartList({ products: products })
  // }, [])


  const dispacher = useDispatch()
  const deleteData = () => {
    const userid = window.localStorage.getItem("token");
    deleteList(userid)
    // if (isLogin === 'true') {
    //   deleteList(userid)

    // } else {
    //   dispach(clereCartLitsItem([]))
    //   dispacher(setCartLeng(0))
    //   // getOffCartList({ products: [] })
    // }
  }

  const deleteSingle = (index) => {
    if (isLogin === 'true') {
      deleteSingleItem({ userid: user_id, index: index, token: token })
    } else {
      const newClone = products.filter((item) => {
        console.log(item);
        // console.log(id);
      })

      // dispach(clearUpdatedProduct())
    }

  }


  const orderConfirm = () => {

    if (isLogin) {
      // tempCart(userid)
      // callSpinner()
      navigate('/checkout')
    } else {
      navigate('/login')
    }
  };

  const couponRef = useRef()

  const sendCouponFree = (val) => {
    // const value = couponRef.current.value
    getDisCoupons({ value: val, id: user_id })
  }

  // const [dataOff, setDataOf] = useState(offCartList)

  const deleteCartList = (id) => {
    const filterdData = products.filter((item) => {
      return item.product !== id
    })
    dispach(clereCartLitsItem(filterdData))
    getOffCartList({ products: filterdData })
  }

  // useEffect(() => {
  //   if (offCartList) {
  //     // setDataOf(offCartList)
  //   }
  // }, [issucOff])


  const { t } = useTranslation()
  const [modalShowCoupen, setModalShowCoupen] = useState(false);
  if (isLogin === 'true') {

    return (
      <>
        <Helmet>
          <title>Cart | Lets Meatz</title>
          <meta
            name="keyword"
            content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
          />
          <meta
            name="description"
            content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
          />
        </Helmet>
        <section className="cartBody">
          {/* {!isSuccess && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>} */}
          {isLoading && <div className="preloaderCount">
            <h4>Coupon Applied</h4>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}
          {isLoadingDelete && <div className="preloaderCount">
            <h4>Product Delete</h4>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="cartTable">
                  <table className="table cartTablePage">
                    <thead>
                      <tr>
                        <th><AiFillDelete /></th>
                        <th>#</th>
                        <th>{t('Product')}</th>
                        {/* <th>Name</th> */}
                        <th>{t('Variation')}</th>
                        <th>Multiply</th>
                        <th>{t('Quantity')}</th>
                        <th>{t('Price')}</th>

                        <th>{t('Tax')}%</th>
                        <th>{t('Tax Amt')}</th>
                        <th>{t('Total')}</th>
                        <th>{t('Delivery Type')}</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {showData?.cart &&
                        showData?.cart?.products?.map((item, i) => {
                          // console.log('cart', item.variant);
                          return (
                            <tr key={i}>
                              <td><AiFillDelete onClick={() => deleteSingle(i)} /></td>
                              <td>{i + 1}</td>
                              <td>
                                <div className="productImageInfo">
                                  <figure>
                                    <Link
                                      to={`/product/${item?.productUid}/${item?.slug}`}
                                      className="productImage"
                                    >
                                      {item?.variant?.mainImage_url?.url ? <img
                                        // src={item?.product?.mainimage_url?.url}
                                        src={item?.variant?.mainImage_url?.url}
                                        alt={`${item?.product?.name}`}
                                      /> : <img
                                        src={productSmall1}
                                        alt={`${item?.product?.name}`}
                                      />}

                                    </Link>
                                  </figure>
                                  <h5>
                                    <Link to={`/product/${item?.productUid}/${item?.slug}`}>
                                      {item?.name}
                                    </Link>{" "}
                                  </h5>
                                </div>
                              </td>
                              <td>{item?.variant?.weight}</td>
                              <td style={{ fontSize: '40px', fontWeight: '900' }}>*</td>

                              <td>
                                <ApiQuatity countValue={item.qty} item={item} getCartData={getCartData} i={i} />
                              </td>

                              <td>
                                {item && (
                                  <h6>{showData?.currency?.symbol} {item.price.sale_rate}</h6>
                                )}
                              </td>



                              <td>
                                {showData?.currency?.symbol}{item?.subtotal}
                              </td>

                              <td>
                                <div style={{ display: "flex", justifyContent: "center" }}>{showData?.currency?.symbol}{item?.tax}</div>
                              </td>
                              {/* <td>
                                <div style={{ display: "flex", justifyContent: "center" }}>{showData?.currency?.symbol}{item.tax}</div>
                              </td> */}
                              <td>
                                <h6>
                                  {showData?.currency?.symbol}  {item?.total}
                                </h6>
                              </td>
                              <td>
                                {item.deliveryType}
                              </td>

                            </tr>
                          );

                        })}
                    </tbody>
                  </table>
                  {showData?.subTotal > 0 && <h5>Grand Total : {currencySymbol} {showData?.grandTotal}</h5>}
                </div>
                <div className="cartDiscountInfo">
                  <div className="updateBtn d-flex">


                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ marginLeft: "10px", backgroundColor: "red" }}
                      onClick={deleteData}
                    >
                      Clear All
                    </button>

                  </div>
                </div>
              </div>


              <div className="col-lg-3">
                <div className="cartTotals">
                  {/* <input placeholder="Apply Coupon" className="form-control" ref={couponRef} />
                  <button type="button" className="btn btn-info" style={{ margin: '10px 0' }} onClick={sendCouponFree}>Apply Coupon</button> */}
                  <Button variant="primary" onClick={() => setModalShowCoupen(true)}>
                    Apply Coupon
                  </Button>
                  {modalShowCoupen && <CoupensList show={modalShowCoupen}
                    onHide={() => setModalShowCoupen(false)} sendCouponFree={sendCouponFree} />}

                  <h5 className="cartTitle">Price Details</h5>

                  <div className="subTotal">
                    <h6>Base Price</h6>
                    <p>{showData?.currency?.symbol} {showData?.cart?.basePrice}</p>
                  </div>

                  <div className="subTotal">
                    <h6>Discount Amount </h6>
                    <p>{showData?.currency?.symbol}  {showData?.cart?.discount}</p>
                  </div>


                  <div className="subTotal">
                    <h6>Sub Total</h6>
                    <p>{showData?.currency?.symbol} {showData?.cart?.subTotal}</p>
                  </div>

                  <div className="subTotal">
                    <h6>Tax Amount</h6>
                    <p>{showData?.currency?.symbol} {showData?.cart?.tax}</p>
                  </div>
                  <div className="subTotal">
                    <h6>Shipping</h6>
                    <p>{showData?.currency?.symbol} {showData?.cart?.products[0]?.shippingCost}</p>
                  </div>

                  <div className="subTotal">
                    <h6>Grand Total</h6>
                    <p>{showData?.currency?.symbol} {showData?.cart?.grandTotal}</p>
                  </div>
                  <hr />
                  <button
                    type="button"
                    className="btn btn-success spinnerBtn"
                    onClick={orderConfirm}
                    style={{ backgroundColor: "green", border: "none", fontSize: '19px' }}
                  >
                    proceed to checkout
                  </button>

                </div>
              </div>


            </div>
          </div>
        </section>
      </>
    )
  } else {
    return <>
      <Helmet>
        <title>Cart | lets Meat</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <section className="cartBody">
        {/* {!isSuccess && <div className="preloaderCount">
       <div className="spinner-border" role="status">
         <span className="visually-hidden">Loading...</span>
       </div>
     </div>} */}

        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="cartTable" style={{ overflow: 'auto' }}>
                <table className="table cartTablePage">
                  <thead>
                    <tr>
                      <th><AiFillDelete /></th>
                      <th>#</th>
                      <th>{t('Product')}</th>
                      {/* <th>Name</th> */}
                      <th>{t('Variation')}</th>
                      <th>{t('Price')}</th>
                      <th>{t('Quantity')}</th>
                      <th>{t('Tax')}%</th>
                      <th>{t('Tax Amt')}</th>
                      <th>{t('Total')}</th>
                      <th>{t('Delivery Type')}</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {showData &&
                      showData?.cart?.products.map((item, i) => {
                        // console.log('show', item);
                        return <tr key={i}>
                          <td>{item?.product?.price?._id ? <AiFillDelete onClick={() => deleteCartList(item?.product?.price?._id)} /> : <AiFillDelete onClick={() => deleteCartList(item?.product?.price?._id)} />}</td>
                          <td>{i + 1}</td>
                          <td>
                            <div className="productImageInfo">
                              <figure>
                                <Link
                                  to={`/product/${item?.product?._id}`}
                                  className="productImage"
                                >
                                  {item?.variant?.mainImage_url?.url ? <img
                                    src={item?.variant?.mainImage_url?.url}
                                  /> : <img
                                    src={item?.product?.mainimage_url?.url}
                                  />}
                                </Link>
                              </figure>
                              <h5>
                                <Link to={`/product/${item?.product?._id}`}>
                                  {item?.name}
                                </Link>
                              </h5>
                            </div>
                          </td>
                          <td>{item?.variant?.weight}</td>
                          <td>
                            <h6>{showData?.currency?.symbol} {item?.price?.mrp}</h6>
                          </td>


                          <td>
                            <ApiQuatity countValue={item.count} item={i} getOffCartList={getOffCartList} i={i} />
                          </td>

                          <td>
                            <div style={{ display: "flex", justifyContent: "center" }}>{item?.tax}</div>
                          </td>
                          <td>
                            <div style={{ display: "flex", justifyContent: "center" }}>{item.tax}</div>
                          </td>
                          <td>
                            <h6>
                              {showData?.currency?.symbol} {item.total}
                            </h6>
                          </td>
                          <td>
                            {item.deliveryType}
                          </td>

                        </tr>


                      })}
                  </tbody>
                </table>
              </div>
              {<h5>{t('Grand Total')} : INR {offCartList?.grandTotal}</h5>}
              <div className="cartDiscountInfo">
                <div className="updateBtn d-flex">


                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ marginLeft: "10px", backgroundColor: "red" }}
                    onClick={deleteData}
                  >
                    {t('CLEAR ALL')}
                  </button>

                </div>
              </div>
            </div>


            <div className="col-lg-3">
              <div className="cartTotals">
                {/* <input placeholder={t('Apply Coupon')} className="form-control" ref={couponRef} />
                <button type="button" className="btn btn-info" style={{ margin: '10px 0' }} onClick={sendCouponFree}>{t('Apply Coupon')}</button> */}
                <h5 className="cartTitle">{t('Price Details')}</h5>

                {/* <div className="subTotal">
                  <h6>Base Price</h6>
                  <p>{offCartList?.basePrice}</p>
                </div>

                <div className="subTotal">
                  <h6>Discount Amount </h6>
                  <p>{offCartList?.discount}</p>
                </div> */}



                <div className="subTotal">
                  <h6>{t('Subtotal')}</h6>
                  <p>{showData?.cart?.subTotal}</p>
                </div>
                <div className="subTotal">
                  <h6>{t('Coupon Applied')}</h6>
                  <p> {showData?.cart?.coupon_id?.discount}</p>
                </div>
                <div className="subTotal">
                  <h6>{t('Shipping')}</h6>
                  <p> {showData?.cart?.products[0]?.shippingCost}</p>
                </div>
                <div className="subTotal">
                  <h6>{t('GST')}</h6>
                  <p> {showData?.cart?.tax}</p>
                </div>
                <div className="subTotal">
                  <h6>{t('Cart Total')}</h6>
                  <p> {showData?.cart?.total}</p>
                </div>
                <hr />

                {isLogin === 'true' ? <button
                  type="button"
                  className="btn btn-success spinnerBtn"
                  onClick={orderConfirm}
                  style={{ backgroundColor: "green", border: "none", fontSize: '19px' }}
                >
                  {t('Proceed To Checkout')}
                </button> :
                  <button
                    type="button"
                    className="btn btn-success spinnerBtn"
                    onClick={() => setModalShow(true)}
                    // onClick={orderConfirm}
                    style={{ backgroundColor: "green", border: "none", fontSize: '19px' }}
                  >
                    {t('Proceed To Checkout')}
                  </button>}


              </div>
            </div>


          </div>
        </div>
      </section>

      {modalShow && <LoginCartSec show={modalShow}
        onHide={() => setModalShow(false)} />}



    </>
  }


}

export default Cart;
