import React from "react";
import { useTranslation } from "react-i18next";

function ContactInfo() {
  const { t } = useTranslation()
  return (
    <>
      <section className="contactContainer p-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contactInfo">
                <h4 className="ls-n-25 m-b-1">{t('Contact Info')}</h4>
                <p>
                  {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed imperdiet libero id nisi euismod, sed porta est consectetur.Vestibulum auctor felis eget orci semper vestibulum.Pellentesque ultricies nibh gravida, accumsan libero luctus,molestie nunc.L orem ipsum dolor sit amet, consectetur adipiscing elit.')}
                </p>
                <p>
                  {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed imperdiet libero id nisi euismod, sed porta est consectetur.Vestibulum auctor felis eget orci semper vestibulum.Pellentesque ultricies nibh gravida, accumsan libero luctus,molestie nunc.L orem ipsum dolor sit amet, consectetur adipiscing elit.')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactInfo;
