import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { BsCheckCircleFill, BsSunFill } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { setCartCalc, setCartLeng, useAddAddressDetailMutation, useClearAllListMutation, useGetActiveBillingQuery, useGetCartQuery, useGetCouponMutation, useGetCurrencyQuery, useGetLanguageQuery, useGetOrderHistoryQuery, usePostBillAddresMutation, useSetCartTemptMutation, useSetEditedAddresMutation } from "../products/productSlice";
import AddShipping from "./AddShipping";
import "./Checkout.css"
import ShippingAddress from "./ShippingAddress";
import { CustomToaster } from "../../common/toaster/CustomToaster";
import payment from '../../assets/img/paymentTing.jpg'
import PaymentSectins from "./PaymentSectins";
import { Button, Spinner } from "react-bootstrap";
import axios from "axios";
import SussessMsg from "./SussessMsg";
import { base_url } from "../../server";
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import CoupensList from "../cart/CoupensList";
const token1 = window.localStorage.getItem("token");
function Checkout() {
  const userid = window.localStorage.getItem("user_id")
  const isLogin = window.localStorage.getItem("isLogin")
  const [shipping, setShipping] = useState(false)
  const navigate = useNavigate()
  const dispacher = useDispatch()

  const [editAdd, { isLoading }] = useSetEditedAddresMutation()
  const [tempCart, { data: plaecedData, isError, isSuccess, isLoading: cartIsLoading }] = useSetCartTemptMutation()

  useEffect(() => {
    if (isSuccess) {
      dispacher(setCartLeng(0))
      dispacher(setCartCalc([]))
    }
  }, [isSuccess])


  const token = window.localStorage.getItem('token')
  const [deleteList] = useClearAllListMutation()
  let isTempLoading = false
  // const [addAddress] = useAddAddressDetailMutation()
  const [showData, setShowData] = useState(null)
  const getCartData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/cart`, { withCredentials: true })
      setShowData(res.data)
    } catch (error) {
    }
  }

  const [showModal, setShowMoal] = useState(true)
  const [cartValue, setCartValue] = useState()
  const { data: cartDetail } = useGetCartQuery(userid)
  const [showTaoster, setShowToaster] = useState({ show: false, message: '', color: 'success' })

  const curr = window.localStorage.getItem('currencySym')
  let currencySymbol = curr
  if (currencySymbol === 'undefined') {
    currencySymbol = 'ZK'
  }
  // const { data: cartval, isSuccess: iscartin } = useGetCartQuery(user_id);

  const { object, cartCal } = useSelector((state) => {
    return state.productList
  })

  const [AddBillAdd, { isLoading: isLoadingBillAdd }] = usePostBillAddresMutation()

  const [alert1, setAlert] = useState(false)
  const [showPayment, seShowPayment] = useState(false)


  const [formData, setFormData] = useState({
    btype: "billing",
    bcountry: "",
    bfirstname: "",
    blastname: "",
    bstate: "",
    bcity: "",
    bemail: "",
    bphone: "",
    bzip: "",
    baddressLine1: "",
    baddressLine2: "",
    blandmark: "",
    bprovince: "",
    bcompany: "",
    timeSlot: '',
    date: '',
    timeGroup: '',
    // userid: window.localStorage.getItem('user_id')
  });

  const [sloteid, setSlote] = useState(null)
  const [groupid, setgroupid] = useState(null)
  const updateAddress = () => {
    if (data?.address?._id) {
      const obj = {
        type: "billing",
        country: formData.bcountry,
        firstname: formData?.bfirstname,
        lastname: formData?.blastname,
        state: formData.bstate,
        city: formData.bcity,
        zip: formData.bzip,
        email: formData?.bemail,
        phone: formData?.bphone,
        addressLine1: formData.baddressLine1,
        addressLine2: formData.baddressLine2,
        landmark: formData.blandmark,
        province: formData.bprovince,
        company: formData.bcompany,
        timeSlot: sloteid,
        date: currentDate,
        timeGroup: groupid,
        selectedBillingAddress: true,
        // userid: window.localStorage.getItem('user_id')
      }
      editAdd({ data: { ...obj, ship_id: data.address._id }, token: token })
    } else {
      AddBillAdd({ data: formData, selectedBillingAddress: true, token: token })
    }

  }


  const handleChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    const cloneData = { ...formData };
    cloneData[name] = val;
    setFormData(cloneData);
    if (e.target.name === 'date') {
      setCurrentDate(e.target.value)
    }
  };

  const setValues = (slotid, groupids) => {
    setSlote(slotid)
    setgroupid(groupids)
  }

  const [shippingAdd, setshippingAdd] = useState(null)
  const setShippingAdd = (item) => {
    setshippingAdd(item)
  }
  let ship = true

  const handlePlace = (e) => {
    const shipAdd = window.localStorage.getItem('shippingId')
    if (!formData.baddressLine1 || !formData.bcountry || !formData.bstate || !formData.bcity || !formData.blandmark) {
      alert('Plese Fill The Billing Address')
      return
    }
    if (shipAdd) {
      ship = false
    }
    e.preventDefault();
    if (isLogin) {
      const paylode = {
        billAddress: formData,
        shipping_Address: shippingAdd,
        // userid: userid,
        Delivery_Status: "Pending",
        Payment_method: "COD",
        Payment_Status: "Unpaid",
        orderStatus: "Not Processed",
        billAddress_Active: ship,
        timeSlot: sloteid,
        date: currentDate,
        timeGroup: groupid
      }
      tempCart(paylode)
    }
    else {
      navigate('/login')
    }

  };

  useEffect(() => {
    if (isError) {
      alert('Something went Wrong Order Not Placed !!')
    } else {
      setCartValue(cartCal)
      alertMsg()
    }
    // setValues()
  }, [isError])


  const alertMsg = () => {
    setAlert(true)
    setTimeout(() => {
      setAlert(false);
      // navigate('/')
    }, 2000);
  }


  // const { data } = useGetActiveBillingQuery(userid)
  const [data, setData] = useState(null)

  const getBillData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/user/getActiveBillAddress`, {
      withCredentials: true,
      headers: {
        "content-type": "application/json; charset=UTF-8",
        authorization: `Bearer ${token1}`,

      },
    })
    setData(res.data)
  }

  useEffect(() => {
    getBillData()
    getCartData()
  }, [])

  const handleToaster = () => {
    setShowToaster({ ...showTaoster, show: false })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const obj = {
      btype: "billing",
      bfirstname: data?.address?.firstname,
      blastname: data?.address?.lastname,
      bcountry: data?.address?.country,
      bstate: data?.address?.state,
      bcity: data?.address?.city,
      bemail: data?.address?.email,
      bphone: data?.address?.phone,
      bzip: data?.address?.zip,
      baddressLine1: data?.address?.addressLine1,
      baddressLine2: data?.address?.addressLine2,
      blandmark: data?.address?.landmark,
      bprovince: data?.address?.province,
      bcompany: data?.address?.company,
      userid: window.localStorage.getItem('user_id')
    }
    setFormData(obj)
    window.localStorage.setItem('shippingId', '')
  }, [data])
  const [getDisCoupons, { isLoading: forCoupons ,isSuccess:cartCopsecc,isError:cartcouer}] = useGetCouponMutation()
  const couponRef = useRef()
  useEffect(() => {
    if (cartCopsecc) {
      getCartData()
    }
    if (cartcouer) {
     alert('Coupon not apply!')
    }
  }, [cartCopsecc,cartcouer])

  const sendCouponFree = (val) => {
    getDisCoupons({ value: val, id: window.localStorage.getItem('user_id') })
  }

  const cashDelevery = () => {
    seShowPayment(false)
  }

  const [data1, setData1] = useState()
  const [timeslot, setTimeslot] = useState(null)
  // console.log('timeslot', timeslot);


  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    // Call the function to set the current date when the component mounts
    setCurrentDate(getFormattedDate());
  }, []);

  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const getPayments1 = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/africanConfig/available`)
      setData1(res.data)
    } catch (error) {

    }
  }

  useEffect(() => {
    getPayments1()
    timeSlotGet()
  }, [])


  const timeSlotGet = async () => {
    try {
      const res = await axios.get(`${base_url}timeGroup/public`, { withCredentials: true })
      setTimeslot(res.data);
    } catch (error) {
      alert(error)
    }
  }

  const { t } = useTranslation()

  const [databill, setDatabill] = useState()
  const getData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/user/billAddress`, {
        withCredentials: true,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      })
      setDatabill(res.data.address)
    } catch (error) {
      alert('Server Error Failed To load Billing Address Data')
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getBillAdd = (e) => {
    const filtered = databill?.find((item) => {
      return item._id == e.target.value
    })
    const obj = {
      btype: "billing",
      bcountry: filtered?.country,
      bstate: filtered?.state,
      bcity: filtered?.city,
      bzip: filtered?.zip,
      baddressLine1: filtered?.addressLine1,
      baddressLine2: filtered?.addressLine2,
      blandmark: filtered?.landmark,
      bprovince: filtered?.province,
      bcompany: filtered?.company,
    }
    setFormData(obj)
  }
  const [modalShowCoupen, setModalShowCoupen] = useState(false);
  return (
    <>
      <Helmet>
        <title>Checkout | Meatz</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <section className="checkoutSec mt-4 mb-4">
        <div className="container">
          {forCoupons && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}

          {cartIsLoading && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}

          <div className="row">
            <div className="col-lg-12">
              {!isLogin && <div className="loginInfo">

                <div className="coupon">
                  <p>
                    New Registration <Link to="/registration">Create Account</Link>
                  </p>
                </div>
                <div className="coupon">
                  <p>
                    Already Have Account <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>}
            </div>
            <div className="col-lg-7">
              <div className="checkoutBody">
                <div className="billingDetails">



                  <div className="selectBill">
                    <Form.Select aria-label="Default select example" onChange={getBillAdd}>
                      <option>Select Billing Address</option>
                      {databill && databill?.map((item) => {
                        return <option value={item._id}>{item?.country}  , {item?.state} , {item?.city} , {item?.zip} , {item?.addressLine1} , {item?.addressLine2} , {item?.landmark}</option>
                      })}

                    </Form.Select>
                  </div>



                  <h5>{t('Billing Details')}</h5>

                  {/* {isSuccess && <div className="preloaderCount">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>} */}

                  <CustomToaster color={showTaoster.color} title={data?.name} show={showTaoster.show} setShow={handleToaster} message={showTaoster.message} position="bottom-end" delay={3000} />



                  <form className="row">
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('First Name')}</label>
                      <input type="text" className="form-control" name="bfirstname" value={formData?.bfirstname} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Last Name')}</label>
                      <input type="text" className="form-control" name="blastname" value={formData?.blastname} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Country')}</label>
                      <input type="text" className="form-control" name="bcountry" value={formData?.bcountry} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Email')}</label>
                      <input type="text" className="form-control" name="bemail" value={formData?.bemail} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Phone')}</label>
                      <input type="text" className="form-control" name="bphone" value={formData?.bphone} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>

                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('State')}</label>
                      <input type="text" className="form-control" name="bstate" value={formData?.bstate} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('City')}</label>
                      <input type="text" className="form-control" name="bcity" value={formData?.bcity} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('ZIP')}</label>
                      <input type="text" className="form-control" name="bzip" value={formData?.bzip} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Province')}</label>
                      <input type="text" className="form-control" name="bprovince" value={formData?.bprovince} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Address Line 1')}</label>
                      <input type="text" className="form-control" name="baddressLine1" value={formData?.baddressLine1} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Address Line 2')}</label>
                      <input type="text" className="form-control" name="baddressLine2" value={formData?.baddressLine2} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Landmark')}</label>
                      <input type="text" className="form-control" name="blandmark" value={formData?.blandmark} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="exampleInputEmail1" className="form-label">{t('Company')}</label>
                      <input type="text" className="form-control" name="bcompany" value={formData?.bcompany} onChange={handleChange} aria-describedby="emailHelp" />
                    </div>

                  </form>
                  {isLoadingBillAdd && <>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <h5>{t('Adding Your Billing Address')}</h5>
                  </>}

                  {data?.address?._id ? <button type="button" className="btn btn-primary" style={{ margin: "10px 0" }} onClick={updateAddress}>{t('Save Address')}</button> : <button type="button" className="btn btn-primary" style={{ margin: "10px 0" }} onClick={updateAddress}>{t('Add Address')}</button>}

                  <form id="checkout-form">



                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={shipping}
                        onChange={() => { }}
                        onClick={() => setShipping(!shipping)}
                      />
                      <label
                        className="form-check-label"
                        onClick={() => setShipping(!shipping)}
                      >
                        {t('Ship to a different address?')}
                      </label>
                    </div>

                    {/* shipping price */}

                    {shipping && (<div>
                      <ShippingAddress setShippingAdd={setShippingAdd} />
                      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <AddShipping setShowMoal={setShowMoal} />
                      </div>

                    </div>)}



                    <div className="form-group mb-3">
                      <label className="order-comments">
                        {t('Order notes (optional)')}
                      </label>
                      <textarea
                        className="form-control"
                        placeholder={t('Notes about your order, e.g. special notes for delivery.')}
                        required
                        defaultValue={""}
                        rows="4"
                        name="notes"
                        onChange={handleChange}
                      />
                    </div>
                    {/* <button type="submit" className="btn btn-primary">
                      place order
                    </button> */}
                  </form>
                </div>
              </div>
              <div className="time-slot">
                <div className="head-time">
                  <h2>{t('Date & Time')} </h2>
                </div>
                <div className="choose-date">
                  <input type="date" className="date-1" name="date" value={currentDate} onChange={handleChange} />
                  {/* <input type="date" defaultValue={defaultValue} /> */}
                </div>
                <div className="morning-slot">
                  <div className="row">
                    {timeslot && timeslot?.map((item) => {
                      return <>
                        <h5><BsSunFill />{item?.displayName}</h5>
                        {item?.value && item?.value?.map((val) => {
                          return <div className="col-lg-4" key={item?._id}>
                            <div className="form-check radioo" id="flexRadioDefault1">
                              <input className="form-check-input" type="radio" name="timeSlot" value={formData?.timeSlot} id="flexRadioDefault1" onChange={() => { setValues(val?.uid, item?.uid) }} />
                              <label className="form-check-label" for="flexRadioDefault1">
                                {val.name}
                              </label>
                            </div>
                          </div>
                        })}
                      </>
                    })}

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">

              <div className="orderInfo" style={{ width: "100%" }}>
                {/* <input placeholder="Apply Coupon" className="form-control" ref={couponRef} />
                <button type="button" className="btn btn-info" style={{ margin: '10px 0' }} onClick={sendCouponFree}>{t('Apply Coupon')}</button> */}
                <Button variant="primary" onClick={() => setModalShowCoupen(true)}>
                  Apply Coupon
                </Button>
                {modalShowCoupen && <CoupensList show={modalShowCoupen}
                  onHide={() => setModalShowCoupen(false)} sendCouponFree={sendCouponFree} />}
                <h5 className="mb-4">{t('Your Order has')} <span style={{ color: "red" }}>{showData?.cart?.products?.length}</span> {t('Items')}</h5>

                <div style={{ overflow: "auto" }}>

                  <table className="table">
                    <thead>
                      <tr className="fontHead">
                        <th scope="col">#</th>
                        <th scope="col">{t('Product')}</th>
                        <th scope="col">{t('Variant')}</th>
                        <th scope="col">{t('Quantity')}</th>

                        <th scope="col">{t('Price')}</th>
                        <th scope="col">{t('Sub Total')}</th>
                        <th scope="col">{t('Tax %')}</th>
                        <th scope="col">{t('Tax')}</th>
                        <th scope="col">{t('Total')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showData?.cart?.products?.map((item, i) => {
                        // console.log('checkout', item);
                        return <tr key={i}>
                          <td>{++i}</td>
                          <td style={{ fontSize: "14px" }}>{item?.name}</td>
                          <td>{item?.variant?.weight}</td>
                          <td>(*{item?.qty})</td>

                          {/* <td>kljklj</td> */}
                          <td>{item?.price?.sale_rate}</td>
                          <td>{item?.subtotal}</td>
                          <td>{item?.tax}</td>
                          <td>{item?.price?.tax}</td>
                          <td>{item?.total}</td>
                        </tr>
                      })}

                    </tbody>
                  </table>
                </div>


                <div className="productAdd" style={{ textAlign: "right" }}>
                  <div className="col">
                    <div className="cartTotals">

                      <h5 className="cartTitle">{t('Price Details')}</h5>
                      <div className="subTotal">
                        <h6>{t('Subtotal')}</h6>
                        <p>{currencySymbol} {showData?.cart?.subTotal}</p>
                      </div>
                      <div className="subTotal">
                        <h6>{t('Coupon Applied')}</h6>
                        <p>{currencySymbol} {showData?.cart?.discount}</p>
                      </div>
                      <div className="subTotal">
                        <h6>{t('Shipping')}</h6>
                        <p>{currencySymbol} {showData?.cart?.products[0]?.shippingCost}</p>
                      </div>
                      <div className="subTotal">
                        <h6>{t('GST')}</h6>
                        <p>{currencySymbol} {showData?.cart?.tax}</p>
                      </div>
                      <hr />
                      <div className="subTotal">
                        <h6>{t('Cart Total')}</h6>
                        <p>{currencySymbol} {showData?.cart?.grandTotal}</p>
                      </div>
                    </div>
                  </div>

                </div>


                <div className="shipping">
                  {/* <h6>Shipping</h6> */}
                  {/* <div className="shippingRadio">
                    <div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Local pickup
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Flat rate
                        </label>
                      </div>
                    </div>
                    <hr />
                  </div> */}
                  <div className="cartTotalFooter">
                    <div className="totalAmount">

                    </div>


                    <div className="paymentMethods">
                      <h4>{t('Payment')} </h4>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault5"
                          id="flexRadioDefault5"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault5"
                          onClick={cashDelevery}
                        >
                          {t('Cash on Delivery/Pay on Delivery')}
                        </label>
                      </div>

                      {/* <p style={{ marginBottom: "5px" }}>
                        <FaInfoCircle />COD
                      </p> */}

                      {/* {data1 && data1.map((item) => {
                        return <p key={item._id} className="d-flex payTingg" style={{ marginTop: "5px" }}
                          // onClick={() => { seShowPayment(!showPayment) }}
                        >
                          <img style={{ width: "25px", marginLeft: "-5px", marginRight: "6px" }} src={payment} /><h6>{item?.name}</h6>
                        </p>
                      })} */}

                      {data1 && data1.map((item, i) => {
                        return <div className="form-check" key={i}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefaul6"
                            id={`flexRadioDefault6${1 + i}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadioDefault6${1 + i}`}
                            onClick={cashDelevery}
                          >
                            {item?.name}
                          </label>
                        </div>
                        //  <p key={item._id} className="d-flex payTingg" style={{ marginTop: "5px" }}
                        //   // onClick={() => { seShowPayment(!showPayment) }}
                        // >
                        //   <img style={{ width: "25px", marginLeft: "-5px", marginRight: "6px" }} src={payment} /><h6>{item?.name}</h6>
                        // </p>
                      })}


                    </div>



                    {showPayment && <div className="paymentProcess">
                      <PaymentSectins />
                    </div>
                    }



                    <div className="proceed">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handlePlace}
                        disabled={!cartCal?.cart?.products}
                      >
                        {t('Place Order')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isSuccess && <SussessMsg plaecedData={plaecedData} cartValue={cartValue} currencySymbol={currencySymbol} />}
    </>
  );
}

export default Checkout;
