import React, { useState } from "react";
import { useContactMessageMutation } from "../../products/productSlice";
import { useTranslation } from "react-i18next";

function ContactForm() {
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
    phone: ""
  })
  const [sendDataContact, { isLoading }] = useContactMessageMutation()

  const chengehandle = (e) => {
    const clone = { ...state }
    clone[e.target.name] = e.target.value
    setState(clone)
  }

  const sendData = () => {
    sendDataContact(state)
    setState({
      name: "",
      email: "",
      message: "",
      phone: ""
    })
  }
  const { t } = useTranslation()
  return (
    <>
      <section className="contactForm p-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 conatct-msg-2">
              <div className="conatct-msg">
                {isLoading && <div className="preloaderCount">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>}
                <h4 className="mt-6 mb-4">{t('Send Us a Message')}</h4>
                <form className="mb-0" action="#">
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-name">
                      {t('Your Name')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="contact-name"
                      name="name"
                      onChange={chengehandle}
                      value={state.name}
                      required
                      placeholder={t('Enter Name')}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      Your E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="contact-email"
                      name="email"
                      onChange={chengehandle}
                      value={state.email}
                      required
                      placeholder={t('Enter Email')}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      Phone
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="contact-email"
                      name="phone"
                      onChange={chengehandle}
                      value={state.phone}
                      required
                      placeholder={t('Phone number')}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-message">
                      Your Message
                    </label>
                    <textarea
                      cols={30}
                      rows={4}
                      id="contact-message"
                      className="form-control"
                      name="message"
                      required
                      onChange={chengehandle}
                      value={state.message}
                      defaultValue={""}
                      placeholder={t('Enter Your Message')}
                    />
                  </div>
                  <div className="form-footer mb-0">
                    <button
                      type="button"
                      className="btn btn-dark font-weight-normal"
                      onClick={sendData}
                      disabled={!state.email || !state.name || !state.message}
                    >
                      {t('Send Message')}
                    </button>
                  </div>
                </form>
              </div>
              {/* <div className="col-lg-6">
              <h4 className="mt-6 mb-4">Frequently Asked Questions</h4>

              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Accordion Item #1
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the first item's accordion body.</strong>{" "}
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Accordion Item #2
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the second item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Accordion Item #3
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the third item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>

             
            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactForm;
