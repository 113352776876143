import React from "react";
import { FaAddressBook } from "react-icons/fa"
import { BsFillPhoneFill } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import { Ri24HoursFill } from "react-icons/ri"
import { useTranslation } from "react-i18next";

function ContactAddress() {
  const { t } = useTranslation()
  return (
    <>
      <section className="connectSec p-30">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                  <FaAddressBook />
                  <h4>{t('Address')}</h4>
                  <p>{t('H No - 239, Sector 16 B Vasundhara Ghaziabad, UP -201012')}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="feature-box text-center">

                <div className="feature-box-content">
                  <BsFillPhoneFill />
                  <h4>{t('Phone Number')}</h4>
                  <p><a href="tel:+91 123-456-7890">+91 123-456-7890</a></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                  <MdEmail />
                  <h4>{t('E-mail Address')}</h4>
                  <p><a href="mailto:example@mail.com">{t('example@mail.com')}</a></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                  <Ri24HoursFill />
                  <h4>{t('Working Days/Hours')}</h4>
                  <p>Mon - Sun / 9:00AM - 8:00PM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactAddress;
