import React from "react";
import { Link } from "react-router-dom";
import pageHeaderBg from "../../../assets/img/leatzmeat/about-img.jpg";
import { useTranslation } from "react-i18next";

function AboutBanner() {
  const { t } = useTranslation()
  return (
    <>
      <section
        className="aboutBanner p-30"
        style={{ background: `url(${pageHeaderBg})` }}
      >
        <div className="container">
          <div className="aboutBannerInfo">
            <h6>{t('ABOUT US')}</h6>
            <h2>{t('OUR COMPANY')}</h2>
            <Link to="/contact" className="btn btn-dark">
              {t('Contact')}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutBanner;
